import { firebase } from '@app/services/firebase';
import {
  Cinema,
  Movie,
  MovieStatus,
  PrintLog,
  ReportPrintLog,
  ReserveSeats,
  ReserveSeatsAdd,
  Schedule,
  SettingsBookingType,
  SettingsCustomerSiteType,
  SettingsDelaysType,
  SettingsFees,
  SettingsOtherLevyType,
  SettingsTaxesType,
  Showtime,
  Transaction,
  UserType,
} from '@app/types';

// import format from 'date-fns/format';
import { addDays, format } from 'date-fns';

const firestore = firebase.firestore();

type UpdateDocumentType = firebase.firestore.DocumentData & {
  updatedBy: { id: string; name: string | null } | null;
  lastUpdatedAt: firebase.firestore.Timestamp;
};

/**
 * Document wrapper with Additionally appends the `updatedAt` and `updatedBy`
 * fields to the document.
 *
 * @param {String} collection Firebase collection name
 * @param {String} id Firebase document id
 * @param {Object} data Updates that needs to be applied
 * @returns {Object} Applied updates to the document
 */
export default function updateDocument(data: firebase.firestore.DocumentData): UpdateDocumentType {
  let updatedBy: { id: string; name: string | null } | null = null;
  const { currentUser } = firebase.auth();
  if (currentUser) {
    updatedBy = { id: currentUser.uid, name: currentUser.displayName };
  }
  const lastUpdatedAt = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  const updates = { ...data, updatedBy, lastUpdatedAt };

  return updates;
}

export function updateUser(uid: string, data: Partial<Omit<UserType, 'authUser'>>): Promise<void> {
  return firestore
    .collection('users')
    .doc(uid)
    .set(
      updateDocument({
        ...data,
      }),
      { merge: true },
    );
}

/**
 * User
 */
export function getUser(
  uid: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('users').doc(uid).get();
}

export function createUser(
  user: Omit<UserType, 'authUser' | 'lastUpdatedAt'>,
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  return firestore.collection('users').add(
    updateDocument({
      ...user,
    }),
  );
}

export function getTicketAgents(): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> {
  return firestore
    .collection('users')
    .where('roles.ticketAgent', '==', true)
    .where('roles.manager', '==', false)
    .where('roles.accountant', '==', false)
    .where('roles.admin', '==', false)
    .get();
}

export function getUsers(): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> {
  return firestore.collection('users').get();
}

/**
 * Cinema
 */
export function getCinemas(): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> {
  return firestore.collection('cinemas').get();
}

export function getCinema(
  uid: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('cinemas').doc(uid).get();
}

export function updateCinema(uid: string, data: Partial<Cinema>): Promise<void> {
  return firestore
    .collection('cinemas')
    .doc(uid)
    .set(
      updateDocument({
        ...data,
      }),
      { merge: true },
    );
}

/**
 * Movie
 */
export function addMovie(
  data: Partial<Movie>,
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  return firestore.collection('movies').add(
    updateDocument({
      ...data,
    }),
  );
}

export function getMovies(
  status: MovieStatus,
  limit?: number,
  orderBy?: {
    field: keyof Movie;
    sort: firebase.firestore.OrderByDirection;
  },
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore.collection('movies').where('status', '==', status);
  if (limit && !Number.isNaN(limit)) {
    query = query.limit(limit);
  }
  if (orderBy) {
    query = query.orderBy(orderBy.field, orderBy.sort);
  } else {
    // default sort by last updated date
    query = query.orderBy('lastUpdatedAt', 'desc');
  }
  return query.get();
}

export function getMovie(
  uid: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('movies').doc(uid).get();
}

export function updateMovie(uid: string, data: Partial<Movie>): Promise<void> {
  return firestore
    .collection('movies')
    .doc(uid)
    .set(
      updateDocument({
        ...data,
      }),
      { merge: true },
    );
}

/**
 * Get Movies from Movie Name
 */
export function getUniqueMovies(
  movieName: any,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('movies')
    .where('name', 'in', movieName)

  return query.orderBy('name', 'asc').orderBy('lastUpdatedAt', 'asc').get();
}


/**
 * Reserve seats
 */
export function addReserveSeats(
  reservation: ReserveSeatsAdd,
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  return firestore.collection('reserveSeats').add(
    updateDocument({
      ...reservation,
    }),
  );
}

export function updateReserveSeatsStatus(
  id: string,
  status: ReserveSeats['status'],
): Promise<void> {
  return firestore
    .collection('reserveSeats')
    .doc(id)
    .set(
      updateDocument({
        ...{
          status,
        },
      }),
      {
        merge: true,
      },
    );
}

export function getReserveSeats(
  user: UserType,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  const { currentUser } = firebase.auth();
  let userFilterId;
  if (!user.roles.admin) {
    userFilterId = currentUser?.uid;
  }
  let query = firestore.collection('reserveSeats').where('status', '==', 'added');
  if (userFilterId) {
    query = query.where('user.id', '==', userFilterId);
  }

  return query.orderBy('createdAt').get();
}

export function validateMovieSlug(slug: string, id?: string): Promise<boolean> {
  return firestore
    .collection('movies')
    .where('slug', '==', slug)
    .get()
    .then((snapshot) => {
      let returnValue: boolean;
      if (id) {
        const item = snapshot.docs.filter((movie) => movie.id !== id);
        returnValue = item.length === 0;
      } else {
        returnValue = snapshot.size === 0;
      }

      return returnValue;
    });
}

/**
 * Schedules
 */

export function addSchedule(uid: string, data: Partial<Schedule>): Promise<void> {
  return firestore
    .collection('schedules')
    .doc(uid)
    .set(
      updateDocument({
        ...data,
      }),
      { merge: true },
    );
}

export function updateSchedule(uid: string, data: Partial<Schedule>): Promise<void> {
  return firestore
    .collection('schedules')
    .doc(uid)
    .set(
      updateDocument({
        ...data,
      }),
      { merge: true },
    );
}

export function removeSchedule(uid: string): Promise<void> {
  return firestore.collection('schedules').doc(uid).delete();
}

export function getSchedules(
  fromDate?: string,
  toDate?: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query:
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    | firebase.firestore.Query<firebase.firestore.DocumentData> = firestore.collection('schedules');

  if (fromDate) {
    query = query.where('dateforSort', '>=', fromDate);
  }

  if (toDate) {
    query = query.where('dateforSort', '<=', toDate);
  }

  return query.orderBy('dateforSort', 'asc').get();
}

/**
 * schedule doc
 */
export function getScheduleDoc(
  uid: string,
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  return firestore.collection('schedules').doc(uid);
}

/**
 * Get Transaction
 */
export function getTransaction(
  tid: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('transactions').doc(tid).get();
}

/**
 * Update Transaction
 * @param tid Transaction Id
 * @param transaction Transaction
 */
export function updateTransaction(tid: string, transaction: Partial<Transaction>): Promise<void> {
  return firestore
    .collection('transactions')
    .doc(tid)
    .set(
      {
        ...transaction,
      },
      {
        merge: true,
      },
    );
}

/**
 * Get Transaction Print Logs
 */
export function getTransactionPrintLogs(
  tid: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('transactions').doc(tid).collection('printLogs').get();
}


/**
 * Get Transaction From Schedule
 */
export function getTransactionFromDate(
  startDate: Date,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('transactions')
    .where('bookingDate', '==', format(startDate, 'dd-MM-yyyy'))

  return query.orderBy('bookingDate', 'asc').orderBy('date', 'asc').get();
}

/**
 * Add Transaction Print Log
 */
export function addTransactionPrintLog(
  tid: string,
  data: Omit<PrintLog, 'id'>,
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  return firestore.collection('transactions').doc(tid).collection('printLogs').add(data);
}


/**
 * Add Cashier Report Print Log
 */
export function addReportPrintLog(
  tid: string,
  data: Omit<ReportPrintLog, 'id'>,
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  return firestore.collection('agentReports').add(
    updateDocument({
      ...data,
    }),
  );
}

/**
 * Get Cashier Report
 */
export function getCashierReport(
  startDate: firebase.firestore.Timestamp,
  endDate: firebase.firestore.Timestamp,
  uid?: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('agentReports')
    // .where('userId', '==', 'zPYAY3tIwRTDdMRBJewlI8JevdW2');
    .where('dateTime', '>=', startDate)
    .where('dateTime', '<=', endDate);
  if (uid) {
    query = query.where('userId', '==', uid);
  }

  return query.orderBy('dateTime', 'desc').orderBy('lastUpdatedAt', 'asc').get();
}

/**
 * Get Transaction Errors
 */
export function getTransactionErrors(
  fromDate: firebase.firestore.Timestamp,
  toDate: firebase.firestore.Timestamp,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  const query = firestore
    .collectionGroup('logs')
    .where('createdAt', '>=', fromDate)
    .where('createdAt', '<=', toDate);

  return query.orderBy('createdAt', 'asc').get();
}

/**
 * Get Transactions
 */
export function getTransactions(
  fromDate: firebase.firestore.Timestamp,
  toDate: firebase.firestore.Timestamp,
  agentId?: string,
  paymentType?: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('transactions')
    .where('date', '>=', fromDate)
    .where('date', '<=', toDate);
  if (agentId) {
    query = query.where('issuerId', '==', agentId);
  }
  if (paymentType) {
    query = query.where('paymentType', '==', paymentType);
  }
  return query.orderBy('date', 'desc').orderBy('createdAt', 'asc').get();
}

/**
 * Get Transactions
 */
export function getFutureTransactions(
  fromDate: firebase.firestore.Timestamp,
  agentId?: string,
  paymentType?: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('transactions')
    .where('date', '>=', addDays(new Date(), -14))
  if (agentId) {
    query = query.where('issuerId', '==', agentId);
  }
  if (paymentType) {
    query = query.where('paymentType', '==', paymentType);
  }
  return query.orderBy('date', 'desc').orderBy('createdAt', 'asc').get();
  // return query.orderBy('bookingDate', 'desc').orderBy('createdAt', 'asc').get();
}

/**
 * Get Agent Reports
 */
export function getAgentReports(
  userId: any,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('agentReports')
    .where('reportID', '==', userId)
  return query.orderBy('reportID', 'asc').limitToLast(1).get();
}


/**
 * Get Complementary Transactions
 */

export function getComplementaryTransactions(
  movie?: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let query = firestore
    .collection('transactions')
    .where('paymentType', '==', 'complimentary')
    .where('status', '==', 'completed');
  if (movie) {
    query = query.where('movie', '==', movie);
  }
  return query.orderBy('date', 'desc').orderBy('createdAt', 'asc').get();
}


/**
 * Get Transactions by email
 */
export function getTransactionsByEmail(
  email: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return firestore
    .collection('transactions')
    .where('email', '==', email)
    .where('status', '==', 'completed')
    .get();
}

/**
 * Get Transactions by scheduleId
 */
export function getTransactionByScheduleId(
  id: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return firestore
    .collection('transactions')
    .where('scheduleId', '==', id)
    .where('status', '==', 'completed')
    .get();
}

/**
 * Get tickets by transaction
 */
export function getTickets(
  tid: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('transactions').doc(tid).collection('tickets').get();
}

/**
 * Get tickets by Id
 */
export function getTicket(
  ticketId: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  return firestore.collectionGroup('tickets').where('id', '==', ticketId).get();
}

/**
 * Get Stats
 */
export function getStats(
  type: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  return firestore.collection('stats').doc(type).get();
}

/**
 * Get Delay Settings
 */
export async function getDelaySettings(): Promise<SettingsDelaysType> {
  const settingsSnap = await firestore.collection('settings').doc('delays').get();
  return settingsSnap.data() as SettingsDelaysType;
}

/**
 * Get Booking Settings
 */
export async function getBookingSettings(): Promise<SettingsBookingType> {
  const settingsSnap = await firestore.collection('settings').doc('bookings').get();
  return settingsSnap.data() as SettingsBookingType;
}

/**
 * Get Other Levy Settings
 */
export async function getOtherLevySettings(): Promise<SettingsOtherLevyType> {
  const settingsSnap = await firestore.collection('settings').doc('otherLevy').get();
  return settingsSnap.data() as SettingsOtherLevyType;
}

/**
 * Get Other Levy Settings
 */
export async function getFeesSettings(): Promise<SettingsFees> {
  const settingsSnap = await firestore.collection('settings').doc('fees').get();
  return settingsSnap.data() as SettingsFees;
}

/**
 * Get Taxes Settings
 */
export async function getTaxesSettings(): Promise<SettingsTaxesType> {
  const settingsSnap = await firestore.collection('settings').doc('taxes').get();
  return settingsSnap.data() as SettingsTaxesType;
}

/**
 * Get Customer Website Settings
 */
export async function getCustomerWebsiteSettings(): Promise<SettingsCustomerSiteType> {
  const settingsSnap = await firestore.collection('settings').doc('customerSite').get();
  return settingsSnap.data() as SettingsCustomerSiteType;
}

/**
 * Save Settings
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function saveSettings(
  settingId: string,
  data: firebase.firestore.DocumentData,
): Promise<void> {
  return firestore
    .collection('settings')
    .doc(settingId)
    .set(updateDocument({ ...data }), { merge: true });
}

/**
 * Get Displays
 */
export function getDisplays(): Promise<
  firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
> {
  return firestore.collection('displays').get();
}

/**
 * Set Display Schedule
 */
export async function setScheduleToDisplay(
  displayId: string,
  scheduleId: string | null,
): Promise<void> {
  return firestore
    .collection('displays')
    .doc(displayId)
    .set(
      updateDocument({
        schedule: scheduleId,
        assigned: scheduleId !== null,
      }),
      {
        merge: true,
      },
    );
}

export async function getBookingsCountOnScheduleId(
  scheduleId: string,
): Promise<{ pending: number; confirmed: number } | null> {
  const confirmedSnap = await firestore
    .collection('transactions')
    .where('scheduleId', '==', scheduleId)
    .where('status', '==', 'completed')
    .get();
  const confirmed = confirmedSnap.size;
  const pendingSnaps = await firestore
    .collection('temporaryBooking')
    .where('scheduleId', '==', scheduleId)
    .where('status', '==', 'active')
    .get();
  const pending = pendingSnaps.size;
  let returned = null;
  if (confirmed > 0 || pending > 0) {
    returned = {
      confirmed,
      pending,
    };
  }
  return returned;
}

/**
 * Generators
 */

export function generateSeates(
  cinemaId: string,
  seatCount: number,
  seatsPerRow: number,
): Promise<void> {
  const alphabet = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
  const seats = [];
  let row = 0;

  for (let i = 1; i <= seatCount; i += 1) {
    row = Math.ceil(i / seatsPerRow);
    const label = `${alphabet[row - 1]}${i - (row - 1) * seatsPerRow}`;
    const item = {
      id: i,
      label,
      letter: alphabet[row - 1],
      status: 'available',
    };
    seats.push(item);
  }
  return firestore.collection('cinemas').doc(cinemaId).set({ seats }, { merge: true });
}

export function generateCinemaShowtimes(cinemaId: string): Promise<void> {
  const showtimes: Showtime[] = [
    {
      id: 0,
      dayOfWeek: 'monday',
      active: false,
      times: [],
    },
    {
      id: 1,
      dayOfWeek: 'tuesday',
      active: false,
      times: [],
    },
    {
      id: 2,
      dayOfWeek: 'wednesday',
      active: false,
      times: [],
    },
    {
      id: 3,
      dayOfWeek: 'thursday',
      active: false,
      times: [],
    },
    {
      id: 4,
      dayOfWeek: 'friday',
      active: false,
      times: [],
    },
    {
      id: 5,
      dayOfWeek: 'saturday',
      active: false,
      times: [],
    },
    {
      id: 6,
      dayOfWeek: 'sunday',
      active: false,
      times: [],
    },
  ];

  return firestore.collection('cinemas').doc(cinemaId).set({ showtimes }, { merge: true });
}
